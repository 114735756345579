import { Component } from "react";

export default class Header extends Component {
    render() {
        return (
            <>
                <nav className="bg-gray-500 flex justify-evenly py-10 text-white text-4xl">
                    <button>Personal</button>
                    <button>Commercial</button>
                    <button>Virtual Gallery</button>
                </nav>
            </>
        );
    }
}