import { Component } from "react";

export default class Main extends Component {
    render() {
        return (
            <>
                <div className="mt-20">
                    <h1 className="text-5xl text-center text-white">Art by K.STOV</h1>
                    <div className="mt-20 px-20 grid grid-cols-4 gap-10">
                        <img src='assets/Donut-Final.png' alt="donut"/>
                        <img src='assets/Motel03.png' alt="motel"/>
                        <img src='assets/room3.png' alt="room"/>
                        <img src='assets/Plant-Sphere.png' alt="sphere"/>
                        <video src="assets/Cube-Animation.mp4" autoPlay loop></video>
                    </div>
                </div>
            </>
        );
    }
}